<template>
  <section class="construction-params" :class="{ 'construction-params--with-filling': sector.filling }">
    <article v-if="!isProAlgorithm" class="construction-params__forms">
      <template v-if="baseParams">
        <h5 class="construction-params__title">
          {{ $t('message.constructionParams.params.title') }}
        </h5>
        <Structure :params="baseParams" type="base" :sector="sector"/>
        <Structure :params="screedParams" type="screed" :sector="sector" v-if="screedParams"/>
      </template>
    </article>
    <article v-else class="construction-params__forms">
      <h5 class="construction-params__title">
        {{ $t('message.constructionParams.facingParams.title') }}
      </h5>
      <Structure :params="facingParams" type="facing" :sector="sector"/>
      <h5 class="construction-params__title">
        {{ $t('message.constructionParams.isolationParams.title') }}
      </h5>
      <Structure :params="isolationParams" type="isolation" :sector="sector"/>
    </article>
    <Index :sectorIndexes="sector.indexes" v-if="readyParams || !baseParams"/>
    <template>
      <ErrorNotification
      v-if="showErrorNotification"
      :withFilling="Boolean(sector.filling) || isSystemWithInitialFilling"
      :notifications="errorNotifications"
      :constructionType="sector.constructionType.type"
      @handle="handleFilling($event)" />
    <Constructions
      v-if="isThereOptionalSystem && !isGettingErrors && (readyParams || !baseParams)"
      :constructions="sector.optionalSystems"
      :selectedConstructions="sector.construction"
      @handle="setActiveConstruction($event)"
    />
    </template>
  </section>
</template>

<script>
import ErrorNotification from '@/components/dump/ErrorNotification'
import Index from '@/components/dump/IndexComponent'
import Structure from '@/components/smart/ConstructionStructure'
import { mapActions, mapGetters } from 'vuex'
import Constructions from '@/components/smart/Constructions'
import { getSuitableSystems } from '@/api'
import { CalcTypes } from '@/utils/types'

export default {
  name: 'ConstructionParams',
  props: ['sector'],
  components: { Constructions, Index, Structure, ErrorNotification },
  watch: {
    selectedOptionalSystem: function(newValue, oldValue) {
      if (newValue && oldValue && this.sector.isCalculated) this.updateSectorParams('isCalculated', false)
      if (newValue !== null) this.getSuitableSystems(this.selectedOptionalSystem)
    },
    readyParams: function(newValue, oldValue) {
      if (!this.isProAlgorithm && oldValue && !newValue) {
        this.updateSectorParams('isCalculated', false)
      }
    },
    base: function () {
      this.reset()
    },
    screed: function () {
      this.reset()
    },
    facing: function () {
      this.reset()
    },
    isolation: function () {
      this.reset()
    }
  },
  mounted() {
    if (this.sector.structures && this.sector.structures.length === 0) {
      this.getOptionalSystem(this.sectorIndex, true)
    }
  },
  computed: {
    ...mapGetters({
      getConstructionTypes: 'getConstructionTypes'
    }),
    sectorIndex() {
      return this.$store.state.sectors.findIndex((sector) => sector.id === this.sector.id)
    },
    errorNotifications() {
      return this.sector.errors
    },
    selectedOptionalSystem() {
      return this.sector.construction
    },
    constructionStructure() {
      return this.sector.structures
    },
    baseParams() {
      return this.constructionStructure
        ?.find((structure) =>
          structure.constructionGroup === this.$t('message.constructionParams.params.base')
        )
    },
    screedParams() {
      return this.constructionStructure
        ?.find((structure) =>
          structure.constructionGroup === this.$t('message.constructionParams.params.screed')
        )
    },
    facingParams() {
      return this.constructionStructure
        ?.find((structure) =>
          structure.constructionGroup === this.$t('message.constructionParams.facingParams.constructionGroup')
        )
    },
    isolationParams() {
      return this.constructionStructure
        ?.find((structure) =>
          structure.constructionGroup === this.$t('message.constructionParams.isolationParams.constructionGroup')
        )
    },
    base() {
      return this.sector.base
    },
    screed() {
      return this.sector.screed
    },
    facing() {
      return this.sector.facing
    },
    isolation() {
      return this.sector.isolation
    },
    isProAlgorithm() {
      return this.sector.algorithm === CalcTypes.pro
    },
    readyParams() {
      if (this.isProAlgorithm) {
        const isFacingParamsReady = Object.values(this.facing).filter((value) => !value).length === 0
        const isIsolationParamsReady = Object.values(this.isolation).filter((value) => !value).length === 0
        const isReady = isFacingParamsReady && isIsolationParamsReady

        if (isReady) {
          this.getConstructionScheme(this.sectorIndex)
          this.getCalculationResult(this.sectorIndex)
        }

        return isReady
      } else {
        const isBaseParamsReady = Object.values(this.base).filter((value) => !value).length === 0
        const isScreedParamsReady = this.screedParams
          ? Object.values(this.screed).filter((value) => !value).length === 0
          : false

        const isReady = this.screedParams ? (isBaseParamsReady && isScreedParamsReady) : isBaseParamsReady

        if (isReady) {
          this.getConstructionScheme(this.sectorIndex)
          this.getOptionalSystem(this.sectorIndex)
        }

        return isReady
      }
    },
    isThereOptionalSystem() {
      return this.sector.optionalSystems?.length
    },
    currentConstructionType() {
      return this.getConstructionTypes.find(type => type.constructionType === this.sector.constructionType.type)
    },
    isSystemWithInitialFilling() {
      // todo: переделать после правки бэка
      return this.sector.system.title.includes('+')
    },
    isGettingErrors() {
      return this.errorNotifications
        ? Object.values(this.errorNotifications).some((error) => error.needRender)
        : false
    },
    showErrorNotification() {
      return this.isGettingErrors || this.sector.filling || this.isSystemWithInitialFilling
    }
  },
  methods: {
    ...mapActions({
      getConstructionScheme: 'getDrawing',
      getOptionalSystem: 'getOptionalSystem',
      setCalculatedIndexes: 'setCalculatedIndexes',
      getCalculationResult: 'getCalculationResult',
      setTestingStatus: 'setTestingStatus'
    }),
    setActiveConstruction(evt) {
      this.updateSectorParams('construction', evt.value)
    },
    getSuitableSystems(system) {
      this.setCalculatedIndexes({ index: this.sectorIndex, payload: { rw: system?.rw, lnw: system?.lnw } })
      this.setTestingStatus({ index: this.sectorIndex, payload: system?.isTested })

      const requestData = {
        optionSystemId: this.sector.construction?.id,
        rw: this.sector.indexes.calculated?.rw,
        lnw: this.sector.indexes.calculated?.lnw,
        rwRequired: this.sector.indexes.required?.rw,
        lnwRequired: this.sector.indexes.required?.lnw
      }

      getSuitableSystems(requestData).then((response) => {
        const errors = {
          rw: {
            text: response.data.rw,
            needRender: response.data.rw !== null ? requestData.rw <= requestData.rwRequired : false
          },
          lnw: {
            text: response.data.lnw,
            needRender: response.data.lnw !== null ? requestData.lnw >= requestData.lnwRequired : false
          }
        }

        this.updateSectorParams('errors', errors)

        this.isGettingErrors
          ? this.updateSectorParams('optionalSystems', null)
          : this.updateSectorParams('isCalculated', true)
      })
    },
    handleFilling(evt) {
      let value = null
      this.resetErrors()

      // todo: переделать после правки бэка
      if (evt.action === 'add') {
        value = this.currentConstructionType?.systems.find((system) => system.id === this.sector.system.fillingFor[0])
      } else if (this.isSystemWithInitialFilling) {
        const replacedSystem = this.currentConstructionType.systems
          .find((system) => system.fillingFor.includes(this.sector.system.id))
        this.updateSectorParams('system', replacedSystem)
      }

      this.updateSectorParams('filling', value)
      if (this.sector.isCalculated) this.updateSectorParams('isCalculated', false)
    },
    updateSectorParams(property, value) {
      this.$store.commit('UPDATE_SECTOR', { index: this.sectorIndex, property, value })
    },
    resetErrors() {
      this.updateSectorParams('errors', null)
      if (this.selectedOptionalSystem) this.updateSectorParams('construction', null)
      this.updateSectorParams('image', null)
    },
    resetCalculationStatus() {
      if (this.sector.isCalculated) {
        this.updateSectorParams('isCalculated', false)
      }
    },
    reset() {
      this.resetCalculationStatus()
      this.resetErrors()
    }
  }
}
</script>

<style scoped lang="sass">
.construction-params
  display: flex
  flex-wrap: wrap
  width: 100%
  &--with-filling
    .construction-params__forms
      order: 1
    .filling
      order: 2
      +media((margin-bottom: (0: rem(10), 768: 0)))
    .indexes
      order: 3
    .constructions
      order: 4
  &__title
    margin: 0 0 rem(20)
    font-weight: 600
    line-height: 1.3
  &__forms
    width: 100%
  &__forms + .indexes
    +media((margin-top: (0: rem(10), 768: rem(30))))
  .filling + .constructions
    margin-top: rem(30)
</style>
