<template>
  <div class="structure" :class="{ 'structure--pro': isProAlgorithm }">
    <div
      class="structure__wrapper"
      :class="{ 'structure__wrapper--facing': isFacing, 'structure__wrapper--base': isBase }"
    >
      <template v-for="param of structure">
        <OptionDropdown
          v-if="param.viewCondition"
          :dropdown="param"
          :options="param.source"
          :validationRules="param.validationRules"
          :type="param.type"
          :key="param.id"
          @update="update($event, type, `${param.id}`)"
        ></OptionDropdown>
      </template>
    </div>
  </div>
</template>

<script>

import OptionDropdown from '@/components/dump/OptionDropdown'
import { CalcTypes } from '@/utils/types'

export default {
  name: 'Structure',
  components: { OptionDropdown },
  props: ['params', 'type', 'sector'],
  computed: {
    isFacing() {
      return this.type === 'facing'
    },
    isBase() {
      return this.type === 'base'
    },
    sectorIndex() {
      return this.$store.state.sectors.findIndex((sector) => sector.id === this.sector.id)
    },
    selected() {
      return this.sector[`${this.type}`]
    },
    materials() {
      return this.params?.materials
    },
    material() {
      return this.materials ? this.materials.find((material) => material.title === this.title) : []
    },
    thicknessUnitType() {
      return this.params ? this.params.thicknessUnitType : ''
    },
    densityUnitType() {
      return this.params ? this.params.densityUnitType : ''
    },
    title() {
      return this.sector[`${this.type}`] && this.sector[`${this.type}`][`${this.type}`]
        ? this.sector[`${this.type}`][`${this.type}`].title
        : ''
    },
    formattedMaterials() {
      return this.materials?.map((material) => {
        return {
          id: material.id,
          value: material.title
        }
      }) ?? []
    },
    densities() {
      return this.title && (this.isFacing || this.isBase) && this.material?.densities
        ? this.material.densities.map((item, index) => ({ id: index, value: parseInt(item, 10) }))
        : [ { id: 0, value: '', unit: this.densityUnitType } ]
    },
    thicknesses() {
      return this.title && this.material?.thicknesses
        ? this.material?.thicknesses.map((item, index) => {
          return item?.size
            ? { id: index, value: item.size, unit: this.thicknessUnitType, count: item.count }
            : { id: index, value: item, unit: this.thicknessUnitType }
        })
        : [ { id: 0, value: '', unit: this.thicknessUnitType } ]
    },
    sheets() {
      return this.title && this.material?.qtySheet
        ? this.material?.qtySheet.map((item, index) => ({
          id: index, value: item, unit: this.$t('message.units.pc')
        }))
        : [ { id: 0, value: '', unit: this.$t('message.units.pc') } ]
    },
    isProAlgorithm() {
      return this.sector.algorithm === CalcTypes.pro
    },
    structure() {
      return [
        {
          id: this.type,
          label: this.getLabel(),
          source: this.formattedMaterials,
          viewCondition: true,
          selected: this.title,
          disabled: false,
          validationRules: null,
          type: 'text'
        },
        {
          id: 'density',
          label: this.$t('message.constructionParams.common.density'),
          source: this.densities,
          viewCondition: this.isFacing || this.isBase,
          selected: this.selected?.density ?? null,
          editable: this.material?.isCustomDensity,
          disabled: !this.title,
          validationRules: this.validationRules[this.isProAlgorithm ? CalcTypes.pro : CalcTypes.lite].density,
          type: 'float'
        },
        {
          id: 'thickness',
          label: this.$t('message.constructionParams.common.thickness'),
          source: this.thicknesses,
          viewCondition: true,
          selected: this.selected?.thickness ?? null,
          editable: this.material?.isCustomThickness,
          disabled: !this.title,
          validationRules: this.validationRules[this.isProAlgorithm ? CalcTypes.pro : CalcTypes.lite].thickness,
          type: 'float'
        },
        {
          id: 'sheets',
          label: this.$t('message.constructionParams.common.sheets'),
          source: this.sheets,
          viewCondition: this.isFacing,
          selected: this.selected?.sheets ?? null,
          disabled: !this.title,
          validationRules: null,
          type: 'text'
        }
      ]
    },
    validationRules() {
      return {
        [CalcTypes.pro]: {
          density: { min: 500, max: 1500 },
          thickness: { min: 5, max: 50 }
        },
        [CalcTypes.lite]: {
          density: { min: 1, max: 500 },
          thickness: { min: 1, max: 500 }
        }
      }
    }
  },
  methods: {
    update(evt, property, subproperty) {
      subproperty === this.type
        ? this.resetConstructionParamsValues(property, evt)
        : this.setConstructionParamsValues(property, subproperty, evt.newValue)
    },
    resetConstructionParamsValues(property, evt) {
      this.structure
        .filter((item) => item.viewCondition)
        .map(item => item.id).forEach((subproperty) => {
          this.setConstructionParamsValues(
            property,
            subproperty,
            subproperty === this.type ? evt.newValue : null
          )
        })

      if (this.sector.construction || this.optionalSystems?.length) {
        this.resetConstructions()
      }
    },
    setConstructionParamsValues(property, subproperty, value) {
      this.$store.commit('UPDATE_SECTOR', {
        index: this.sectorIndex,
        property,
        subproperty,
        value
      })
    },
    getLabel() {
      switch (this.type) {
        case 'facing':
          return this.$t('message.constructionParams.facingParams.facing')
        case 'isolation':
          return this.$t('message.constructionParams.isolationParams.isolation')
        case 'base':
          return this.$t('message.constructionParams.params.base')
        case 'screed':
          return this.$t('message.constructionParams.params.screed')
        default:
          return ''
      }
    },
    resetConstructions() {
      ['construction', 'optionalSystems']
        .forEach((property) => this.setConstructionParamsValues(property, null, null))
    }
  }
}
</script>

<style lang="sass">
.structure
  &__wrapper
    display: grid
    grid-gap: rem(16)
    margin: 0 0 rem(20)
    grid-template-rows: auto
    +media((grid-template-columns: (0: repeat(2, 1fr), 1230: minmax(0, rem(357)) rem(213) rem(213))))
    +media((grid-template-areas: (0: 'screed screed' 'thickness thickness', 1230: 'screed screed thickness' 'screed screed thickness')))
    &--base
      +media((grid-template-rows: (0: 1fr 1fr 1fr, 600: 1fr 1fr, 1230: 1fr 1fr)))
      +media((grid-template-areas: (0: 'base base' 'density density' 'thickness thickness', 600: 'base base' 'density thickness', 1230: 'base density thickness' 'base density thickness')))
  &--pro
    .structure__wrapper
      grid-gap: rem(16)
      margin: 0 0 rem(20)
      grid-template-rows: auto
      +media((grid-template-columns: (0: repeat(2, 1fr), 1230: 50% 25% 25%)))
      +media((grid-template-areas: (0: 'isolation isolation' 'thickness thickness', 800: 'isolation thickness' 'isolation thickness', 1230: 'isolation thickness .' 'isolation thickness .')))
      &--facing
        +media((grid-template-columns: (0: repeat(2, 1fr), 1230: 50% 25% 25%)))
        +media((grid-template-areas: (0: 'facing facing' 'density density' 'thickness thickness' 'sheets sheets', 800: 'facing density' 'thickness sheets', 1230: 'facing density thickness' '. sheets sheets')))
        margin-bottom: rem(30)
</style>
