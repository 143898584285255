import { render, staticRenderFns } from "./ConstructionStructure.vue?vue&type=template&id=18e1a232"
import script from "./ConstructionStructure.vue?vue&type=script&lang=js"
export * from "./ConstructionStructure.vue?vue&type=script&lang=js"
import style0 from "./ConstructionStructure.vue?vue&type=style&index=0&id=18e1a232&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports